import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import RadioStationRepository from '@/abstraction/repository/radioStationRepository'

const repository = new RadioStationRepository()

export default function useRadioStationList() {
    // Use toast
    const toast = useToast()

    // Table Handlers
    const tableColumns = [
        { key: 'radio', sortable: true },
        { key: 'description' },
        { key: 'link', sortable: true },
        { key: 'actions' },
    ]
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const radioStations = ref(null)
    const pagination = ref({})

    const fetchRadioStation = async (paginate = {page: 1, itemsPerPage: perPage.value}, filters= {}) => {
        const radioStationList = await repository.index({filters, pagination: paginate})
        radioStations.value = radioStationList.data
        pagination.value = radioStationList.pagination
        return radioStations
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'danger'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }

    const deleteRadioStation = async (id) => {
        try {
            const response = await repository.delete(id)
            pagination.value.itemsLength--
            const index = radioStations.value.findIndex(x => x.id === id)
            radioStations.value.splice(index, 1)
            return response
        } catch (e) {
            return false
        }
    }

    return {
        fetchRadioStation,
        tableColumns,
        perPage,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        radioStations,
        pagination,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        deleteRadioStation,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    }
}
