<template>
  <component :is="form === undefined ? 'div' : 'b-card'">
    <template>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <!-- media -->
              <b-media no-body>
                <b-media-body class="mt-75 ml-75">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <!-- upload button -->

                      <image-cropper
                        v-model="fileForm"
                        label="manager image"
                        :url.sync="form.thumbnail"
                      />
                      <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                      <!--/ upload button -->
                    </b-col>
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <div
                        class="
                          d-flex
                          h-100
                          align-items-center
                          justify-content-end
                        "
                      >
                        <div class="w-50 text-right">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="mr-75 w-100"
                            @click="$refs.uploadMusic.click()"
                          >
                            <span><i class="WMi-upload" /></span> upload music
                          </b-button>
                          <input
                            ref="uploadMusic"
                            type="file"
                            class="d-none"
                            @change="handleMusicFile"
                          >
                          <audio
                            ref="audio"
                            controls
                            :class="[
                              'w-100 mt-2 ',
                              {
                                'd-block': uploadedMusic,
                                'd-none': !uploadedMusic,
                              },
                            ]"
                          >
                            <source
                              ref="musicFile"
                              src=""
                            >
                          </audio>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-media-body>
              </b-media>
              <!--/ media -->
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <!-- Name -->
            <b-col
              cols="12"
              sm="6"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Arabic Name -->
            <b-col
              cols="12"
              sm="6"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="arabicName"
                rules="required"
              >
                <b-form-group
                  label="Arabic Name"
                  label-for="arabic-name"
                >
                  <b-form-input
                    id="arabic-name"
                    v-model="form.name_ar"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Link -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="link"
                rules="required|url"
              >
                <b-form-group
                  label="Link"
                  label-for="link"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="link"
                    v-model="form.link"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Description -->
<!--            <b-col cols="12">-->
<!--              <validation-provider-->
<!--                #default="validationContext"-->
<!--                name="Description"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-group-->
<!--                  label="Description"-->
<!--                  label-for="Description"-->
<!--                  :state="getValidationState(validationContext)"-->
<!--                >-->
<!--                  <b-form-textarea-->
<!--                    v-model="form.description"-->
<!--                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                    :reduce="(val) => val.value"-->
<!--                    input-id="Description"-->
<!--                    rows="3"-->
<!--                  />-->
<!--                  <b-form-invalid-feedback-->
<!--                    :state="getValidationState(validationContext)"-->
<!--                  >-->
<!--                    {{ validationContext.errors[0] }}-->
<!--                  </b-form-invalid-feedback>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
            <!-- Live -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Live"
              >
                <b-form-group
                  label="Live"
                  label-for="live"
                >
                  <b-form-checkbox
                    v-model="form.live"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    input-id="live"
                    name="live"
                    switch
                    inline
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Form Actions -->
            <b-col cols="12">
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-2"
                  type="submit"
                >
                  {{ radioStationId ? "update" : "add" }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-danger"
                >
                  reset
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </component>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BFormFile,
    BImg,
    BFormTextarea,
    BCardText,
    BButton,
    BMediaBody,
    BMediaAside,
    BFormInvalidFeedback,
    BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, url } from '@validations'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
// repository
import FileRepository from '@/abstraction/repository/fileRepository'
import RadioStationRepository from '@/abstraction/repository/radioStationRepository'
// random token
import { makeid } from '@/utils/math'
import useRadioStationList from './useRadioStationList'
import userStoreModule from '../user/userStoreModule'

const radioStationRepository = new RadioStationRepository()
const RANDOM_TOKEN = makeid(50)

export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        url,
        uploadedMusic: false,
    }),
    watch: {
        '$route.params': function (to, from) {
            this.radioStationId = to.id
        },
    },
    setup() {
        const blankForm = {
            name: '',
            name_ar: '',
            // description: '',
            link: '',
            live: false,
            file_batch_id: RANDOM_TOKEN,
        }
        const radioStationId = ref(router.currentRoute.params.id)
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: 'main_image',
            model_name: 'radio_station',
            crop: {},
        })
        const roleOptions = [
            { label: 'Admin', value: 'admin' },
            { label: 'Author', value: 'author' },
            { label: 'Editor', value: 'editor' },
            { label: 'Maintainer', value: 'maintainer' },
            { label: 'Subscriber', value: 'subscriber' },
        ]

        const planOptions = [
            { label: 'Basic', value: 'basic' },
            { label: 'Company', value: 'company' },
            { label: 'Enterprise', value: 'enterprise' },
            { label: 'Team', value: 'team' },
        ]
        const form = ref(JSON.parse(JSON.stringify(blankForm)))

        const USER_APP_STORE_MODULE_NAME = 'app-user'

        const { refetchData } = useRadioStationList()
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        onMounted(async () => {
            if (radioStationId.value) {
                const resource = await radioStationRepository.show(radioStationId.value)
                form.value = resource
                form.value.file_batch_id = RANDOM_TOKEN
                console.log(form)
            }
        })
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankForm))
        }
        const onSubmit = async () => {
            try {
                const fileRepository = new FileRepository()
                if (router.currentRoute.params.id) {
                    if (fileForm.value.file) {
                        await fileRepository.store(fileForm.value)
                    }
                    await radioStationRepository.update(form.value.id, form.value)
                } else {
                    await fileRepository.store(fileForm.value)
                    await radioStationRepository.store(form.value)
                }
                router.push({ name: 'apps-radio-station-list' })
            } catch (e) {
                console.log(e)
                return e
            }
        }
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform)
        return {
            form,
            refetchData,
            onSubmit,
            fileForm,
            radioStationId,

            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            roleOptions,
            planOptions,
        }
    },
    created() {
        this.radioStationId = this.$route.params.id
    },
    methods: {
        handleMusicFile(event) {
            const { files } = event.target
            this.$refs.musicFile.setAttribute('src', URL.createObjectURL(files[0]))
            this.$refs.audio.load()
            this.uploadedMusic = true
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
